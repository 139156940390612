import React from 'react'
import emailjs from "@emailjs/browser";
import { useRef } from 'react';
function ContactWrapper() {
  const form =useRef();
  const sendEmail=(e)=>{
    e.preventDefault();
    
    emailjs.sendForm('service_5p2tgz3','template_w6m2qyo',form.current,'hv8RKgUgWsLCWkGaP')
      .then((result) => {
          console.log(result.text);
          alert('Thank You for showing interest in our services!! We will contact you soon.');
           e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };
   

  return (
    <>
     <section className="contact-area sec-mar">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="contact-left">
                <div className="sec-title layout2">
                  <span>Get in touch</span>
                  <h2>Have Ideas in mind? <br></br>Lets enhance and Make it to Reality</h2>
                </div>
                <ul className="social-follow">
                  <li><a rel="noopener noreferrer" href="https://www.facebook.com/digihunktech"><i className="fab fa-facebook-f" /></a></li>
                  <li><a rel="noopener noreferrer" href="https://www.twitter.com/digihunktech"><i className="fab fa-twitter" /></a></li>
                  <li><a rel="noopener noreferrer" href="https://www.linkedin.com/company/digihunktech/"><i className="fab fa-linkedin" /></a></li>
                  <li><a rel="noopener noreferrer" href="https://www.instagram.com/digihunk"><i className="fab fa-instagram" /></a></li>
                </ul>
                <div className="informations">
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="info">
                      <h3>Address:</h3>
                      <p>RZH-666, Street No-16, Raj Nagar-2, Palam, New Delhi-110077</p>
                    </div>
                    
                  </div>
                  
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="info">
                      <h3>Phone</h3>
                      <a href="tel:09990438600">+91-99904 38600</a>
                     
                      
                    </div>
                  </div>
                  
                  <div className="single-info">
                    <div className="icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="info">
                      <h3>Email</h3>
                       <a href="tell:care@digihunk.com">info@digihunk.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
               <div className="getin-touch">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-form wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                  <h3>Start growing your business with us</h3>
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-12">
                        <input type="text" name="user_name" placeholder="Enter your name" required />
                      </div>
                      <div className="col-md-12">
                        <input type="text" name="user_phone" placeholder="Enter your phone number" required/>
                      </div>
                      <div className="col-md-12">
                        <input type="email" name="user_email" placeholder="Enter your email" required/>
                      </div>
                      
                      <div className="col-12">
                        <textarea name="message" cols={30} rows={10} placeholder="Your message" defaultValue={""} />
                        <input type="submit" defaultValue="Send Message" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              
            </div>
          </div>
            </div>
          </div>
          
        </div>
      </section>   
    </>
  )
}

export default ContactWrapper